<template>
  <div id="chargingRules">
    <el-dialog
      title="编辑收费规则"
      :visible.sync="show"
      top="20px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @opened="opened"
      @close="$refs.timeRule.reset()"
      @closed="reset"
    >
      <div class="basicsInfo">
        <h3>基础信息</h3>
        <el-form
          :model="baseForm"
          :rules="baseRules"
          ref="baseForm"
          label-width="100px"
        >
          <el-form-item label="收费规则名称" prop="priceName">
            <el-input
              v-model="baseForm.priceName"
              placeholder="请输入收费规则名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="收费规则类型" prop="priceType">
            <el-select
              v-model="baseForm.priceType"
              placeholder="请选择收费规则类型"
              @change="priceTypeSelectChange"
            >
              <el-option
                v-for="item of priceTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="计费周期"
            prop="countWithArrival"
            v-if="baseForm.priceType === '组合收费'"
          >
            <el-select
              v-model="baseForm.countWithArrival"
              placeholder="请选择计费周期"
            >
              <el-option
                v-for="item of countWithArrivalList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="计费周期类型"
            prop="periodHours"
            v-if="baseForm.priceType === '按时长收费'"
          >
            <el-select
              v-model="baseForm.periodHours"
              placeholder="请选择计费周期类型"
              @change="periodHoursChange"
            >
              <el-option
                v-for="item of periodHoursList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="charge-preview">
        <div class="charge">
          <div class="basicsInfo">
            <h3>收费规则</h3>
            <el-form
              :model="chargeForm"
              :rules="chargeRules"
              ref="chargeForm"
              label-width="110px"
            >
              <div class="freeDuration">
                <el-form-item label="免费时长" prop="freeDuration">
                  <el-input
                    v-model="chargeForm.freeDuration"
                    placeholder="请输入免费时长"
                  ></el-input>
                  <el-select v-model="chargeForm.freeUnit">
                    <el-option
                      v-for="item of unitList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-checkbox v-model="chargeForm.freeIncluded"
                  >超出免费时长后，免费时长计费</el-checkbox
                >
              </div>
              <el-form-item label="黄牌车双倍收费" prop="allowDoubleCharge">
                <el-radio-group v-model="chargeForm.allowDoubleCharge">
                  <el-radio label="否">否</el-radio>
                  <el-radio label="是">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="单日单次最高收费">
                <el-input
                  v-model="chargeForm.highestCharge"
                  placeholder="请输入单日单次最高收费"
                >
                  <span slot="suffix">元</span></el-input
                >
              </el-form-item>
              <el-form-item label="单日多次最高收费">
                <el-input
                  v-model="chargeForm.vehicleTopCharge"
                  placeholder="请输入单日多次最高收费"
                >
                  <span slot="suffix">元</span></el-input
                >
              </el-form-item>
              <div class="once">
                <el-form-item label="前">
                  <el-input v-model="chargeForm.onceDuration"
                    ><span slot="suffix">分钟</span></el-input
                  >
                </el-form-item>
                <el-form-item label="固定收取" class="onceCharge">
                  <el-input v-model="chargeForm.onceCharge"
                    ><span slot="suffix">元</span></el-input
                  >
                </el-form-item>
              </div>
              <el-form-item label="备注">
                <el-input
                  type="textarea"
                  placeholder="请输入备注内容"
                  v-model="chargeForm.priceComment"
                  maxlength="60"
                  show-word-limit
                  resize="none"
                >
                </el-input>
              </el-form-item>
              <div class="freeLeaveMinutes">
                <el-form-item prop="freeLeaveMinutes">
                  <el-input v-model="chargeForm.freeLeaveMinutes"
                    ><span slot="suffix">分钟</span></el-input
                  >
                  内离场，超时加收停车费
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="detailRulesSet">
            <nav>
              <p>详细规则设置</p>
              <div v-if="baseForm.priceType === '组合收费'">
                <el-button type="primary" size="medium" @click="addTimeQuantum"
                  >添加时段</el-button
                >
                <el-button
                  size="medium"
                  @click="delTimeQuantum"
                  v-if="detailForm.priceDetailList.length > 1"
                  >删除时段{{ detailForm.priceDetailList.length }}</el-button
                >
              </div>
            </nav>
            <div class="setContent" v-if="baseForm.priceType === '组合收费'">
              <el-form
                :model="detailForm"
                :rules="detailRules"
                ref="detailForm"
                label-width="70px"
              >
                <div
                  class="timeQuantum"
                  v-for="(item, index) of detailForm.priceDetailList"
                  :key="index"
                >
                  <el-form-item
                    :label="'时段' + (index + 1) + ':'"
                    class="priceDetailList"
                  >
                    <el-form-item
                      class="time"
                      :prop="`priceDetailList_${index}`"
                    >
                      <el-time-picker
                        format="HH:mm"
                        value-format="HHmm"
                        :editable="false"
                        v-model="item.timeStart"
                        :clearable="false"
                        @change="timeChange('timeStart', index, $event)"
                      >
                      </el-time-picker>
                      <span class="until">至</span>
                      <el-time-picker
                        format="HH:mm"
                        value-format="HHmm"
                        :editable="false"
                        v-model="item.timeEnd"
                        :clearable="false"
                        @change="timeChange('timeEnd', index, $event)"
                      >
                      </el-time-picker>
                    </el-form-item>
                    <div class="highestCharge">
                      该时间段最高收费
                      <el-input v-model="item.highestCharge"
                        ><span slot="suffix">元</span></el-input
                      >
                    </div>
                  </el-form-item>
                  <el-form-item label="类型">
                    <el-radio
                      v-model="item.priceType"
                      label="按时长收费"
                      @change="priceTypeChange(index)"
                      >按时长收费</el-radio
                    >
                    <el-radio
                      v-model="item.priceType"
                      label="按次收费"
                      @change="priceTypeChange(index)"
                      >按次收费</el-radio
                    >
                  </el-form-item>
                  <div
                    v-if="item.priceType === '按时长收费'"
                    class="longCharge"
                  >
                    <el-form-item
                      :label="'时长' + (indexs + 1) + ':'"
                      v-for="(items, indexs) of item.priceUnitList"
                      :key="items.id"
                      :prop="`priceUnitList_${index}_${indexs}`"
                    >
                      <div class="priceUnit">
                        <div>
                          <el-input
                            v-model="items.fromTime"
                            :disabled="indexs === 0"
                          ></el-input>

                          <el-select
                            v-model="items.countUnit"
                            @change="countUnitChange(index, $event)"
                            placeholder=""
                          >
                            <el-option
                              v-for="unit of unitList"
                              :key="unit.value"
                              :label="unit.label"
                              :value="unit.value"
                            ></el-option>
                          </el-select>
                        </div>
                        <span>-</span>
                        <div>
                          <el-input v-model="items.toTime"></el-input>

                          <el-select
                            v-model="items.countUnit"
                            @change="countUnitChange(index, $event)"
                            placeholder=""
                          >
                            <el-option
                              v-for="unit of unitList"
                              :key="unit.value"
                              :label="unit.label"
                              :value="unit.value"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="priceUnitList_charge">
                        <span>收费</span>
                        <div>
                          <div class="chargeAmount">
                            <el-input v-model="items.chargeAmount">
                              <span slot="suffix">元</span></el-input
                            >
                          </div>
                        </div>

                        <span class="obliqueLine">/</span>
                        <div>
                          <el-input v-model="items.chargeTime"></el-input>
                          <el-select v-model="items.chargeUnit" placeholder="">
                            <el-option
                              v-for="unit of unitList"
                              :key="unit.value"
                              :label="unit.label"
                              :value="unit.value"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                      <el-button
                        type="text"
                        @click="delPriceUnit(index, indexs)"
                        v-if="
                          indexs !== 0 &&
                          indexs === item.priceUnitList.length - 1
                        "
                        >删除</el-button
                      >
                    </el-form-item>
                    <el-button
                      size="medium"
                      class="addPriceUnit"
                      @click="addPriceUnit(index)"
                      ><i class="iconfont icon-jiahao"></i> 添加时长</el-button
                    >
                  </div>
                  <div v-else class="onceCharge">
                    <el-form-item label="按次收费">
                      <el-input v-model="item.onceCharge"
                        ><span slot="suffix">元</span></el-input
                      >
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </div>
            <time-rule
              class="setContent"
              v-show="baseForm.priceType === '按时长收费'"
              :periodHours="baseForm.periodHours"
              ref="timeRule"
            />
          </div>
        </div>
        <div class="preview">
          <div class="basicsInfo">
            <h3>预览区</h3>
            <rule-preview
              :baseForm="baseForm"
              :chargeForm="chargeForm"
              :detailForm="detailForm"
              :priceType="baseForm.priceType"
              :periodHours="baseForm.periodHours"
              :timeRuleForm="timeRuleForm"
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="saveBtn"
          :loading="loading"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { timeDiff } from "./util";
export default {
  components: {
    rulePreview: () => import("@/components/chargingRules/rulePreview"),
    timeRule: () => import("@/components/chargingRules/timeRule"),
  },
  data() {
    return {
      loading: false,
      show: false,
      //规则id
      priceId: null,
      //计费周期类型列表
      priceTypeList: [
        {
          value: "组合收费",
          label: "组合收费规则(自定义24小时)",
        },
        {
          value: "按时长收费",
          label: "时长收费规则",
        },
      ],
      //计费周期列表
      countWithArrivalList: [
        {
          value: "否",
          label: "自然日",
        },
        {
          value: "是",
          label: "进场后24小时",
        },
      ],
      periodHoursList: [
        {
          value: 12,
          label: "车辆进场后12小时",
        },
        {
          value: 24,
          label: "车辆进场后24小时",
        },
      ],
      //单位列表
      unitList: [
        {
          value: "分钟",
          babel: "分钟",
        },
        {
          value: "小时",
          babel: "小时",
        },
      ],
      //基础信息表单
      baseForm: {
        //收费规则名称
        priceName: "",
        //收费规则类型
        priceType: "组合收费",
        //计费周期类型
        periodHours: 24,
        //计费周期
        countWithArrival: "否",
        //普通/业主/充电/临时
        priceVersion: "普通",
      },
      //基础信息表单验证
      baseRules: {
        priceName: [
          { required: true, message: "请输入收费规则名称", trigger: "blur" },
        ],
        billingType: [
          { required: true, message: "请选择收费规则类型", trigger: "change" },
        ],
        countWithArrival: [
          { required: true, message: "请选择计费周期", trigger: "change" },
        ],
        periodHours: [
          { required: true, message: "请选择计费周期类型", trigger: "change" },
        ],
      },
      //收费规则表单
      chargeForm: {
        //免费时长
        freeDuration: "",
        //免费时长单位
        freeUnit: "分钟",
        //超出免费时长后，免费时长计费
        freeIncluded: true,
        // 黄牌车双倍收费
        allowDoubleCharge: "否",
        //单日单次最高收费
        highestCharge: "",
        // 单日多次最高收费
        vehicleTopCharge: "",
        //前XX分钟收费YY元中的XX分钟
        onceDuration: "",
        //前XX分钟收费YY元中的YY元
        onceCharge: "",
        //前XX分钟收费YY元中的时间单位 (固定的)
        onceUnit: "分钟",
        //备注
        priceComment: null,
        //xx分钟内离场，超时加收停车费的xx
        freeLeaveMinutes: "",
      },
      //收费规则验证
      chargeRules: {
        freeDuration: [
          { required: true, message: "请输入免费时长", trigger: "blur" },
        ],
        allowDoubleCharge: [
          { required: true, message: "请选择黄牌车双倍收费", trigger: "blur" },
        ],
        freeLeaveMinutes: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
      },
      //按时长收费规则组件数据
      timeRuleForm: null,
      //详细规则设置表单
      detailForm: {
        //时间段列表
        priceDetailList: [
          {
            //开始时段
            timeStart: "0000",
            //结束时段
            timeEnd: "0000",
            //收费类型
            priceType: "按时长收费",
            //该时间段最高收费
            highestCharge: "",
            //按次收费时的金额
            onceCharge: "",
            //时长区间列表
            priceUnitList: [
              {
                //生成随机数做key值
                id: Math.random(),
                //时长的开始时长
                fromTime: "0",
                //时长的结束时长
                toTime: "",
                //时长的时长单位
                countUnit: "小时",
                //收费的金额
                chargeAmount: "",
                //收费的时长
                chargeTime: "",
                //收费的时长单位
                chargeUnit: "小时",
              },
            ],
          },
        ],
      },
      //详细规则设置表单验证
      detailRules: {
        //第一个0表示第几个时段的下标，第二个0表示第0时段下面的第几个时长
        priceUnitList_0_0: [
          { validator: this.priceUnit_relus, trigger: "blur" },
        ],
        priceDetailList_0: [
          { validator: this.priceDetail_relus, trigger: "blur" },
        ],
      },
      //点击添加时长的时候只校验当前点击的时段 -1为校验全部时段
      addPriceUnit_index: 0,
    };
  },
  methods: {
    priceTypeSelectChange() {
      this.timeRuleForm = this.$refs.timeRule.timeRuleForm;
    },
    opened() {
      if (
        this.timeRuleForm &&
        this.priceId &&
        this.baseForm.priceType === "按时长收费"
      ) {
        this.$refs.timeRule.timeRuleForm = this.timeRuleForm;
      }
    },
    priceTypeChange(index) {
      if (this.detailForm.priceDetailList[index].priceUnitList.length === 0) {
        Object.assign(this.detailRules, {
          [`priceUnitList_${index}_0`]: [
            { validator: this.priceUnit_relus, trigger: "blur" },
          ],
        });
        this.detailForm.priceDetailList[index].priceUnitList = [
          {
            id: Math.random(),
            fromTime: "0",
            toTime: "",
            countUnit: "小时",
            chargeAmount: "",
            chargeTime: "",
            chargeUnit: "小时",
          },
        ];
      }
    },
    //时段的时间改变
    timeChange(type, index, event) {
      if (this.detailForm.priceDetailList.length > 1) {
        if (type === "timeStart") {
          if (index !== 0) {
            this.detailForm.priceDetailList[index - 1].timeEnd = event;
          }
        } else if (type === "timeEnd") {
          if (index !== this.detailForm.priceDetailList.length - 1) {
            this.detailForm.priceDetailList[index + 1].timeStart = event;
          }
        }
      }
    },
    //重置所有表格
    reset() {
      this.priceId = null;
      this.timeRuleForm = null;
      this.addPriceUnit_index = 0;
      this.baseForm = {
        priceName: "",
        priceType: "组合收费",
        periodHours: 24,
        priceVersion: "普通",
        countWithArrival: "否",
      };
      this.chargeForm = {
        freeDuration: "",
        freeUnit: "分钟",
        freeIncluded: true,
        highestCharge: "",
        allowDoubleCharge: "",
        vehicleTopCharge: "",
        onceDuration: "",
        onceCharge: "",
        onceUnit: "分钟",
        priceComment: "",
        freeLeaveMinutes: "",
      };
      this.detailForm = {
        priceDetailList: [
          {
            timeStart: "0000",
            timeEnd: "0000",
            priceType: "按时长收费",
            highestCharge: "",
            onceCharge: "",
            priceUnitList: [
              {
                id: Math.random(),
                fromTime: "0",
                toTime: "",
                countUnit: "小时",
                chargeAmount: "",
                chargeTime: "",
                chargeUnit: "小时",
              },
            ],
          },
        ],
      };
    },
    //修改时段中时长的单位
    countUnitChange(index, event) {
      let priceDetailList = this.detailForm.priceDetailList[index];
      for (let item of priceDetailList.priceUnitList) {
        item.countUnit = event;
      }
    },
    //校验添加时长的逻辑
    priceUnit_relus(rule, value, callback) {
      let index = parseInt(rule.field.split("_")[1]);
      if (this.addPriceUnit_index === index || this.addPriceUnit_index === -1) {
        let indexs = parseInt(rule.field.split("_")[2]);
        let priceUnitList =
          this.detailForm.priceDetailList[index].priceUnitList;
        let timeStart = this.detailForm.priceDetailList[index].timeStart;
        let timeEnd = this.detailForm.priceDetailList[index].timeEnd;
        let diff = timeDiff(timeStart, timeEnd, priceUnitList[0].countUnit);
        if (!priceUnitList[indexs].toTime) {
          return callback(new Error("请输入结束时长"));
        }
        if (!(priceUnitList[indexs].chargeAmount >= 0)) {
          return callback(new Error("请输入收费金额"));
        }
        if (!priceUnitList[indexs].chargeTime) {
          return callback(new Error("请输入收费的时长"));
        }
        if (indexs === 0) {
          if (priceUnitList[indexs].toTime > diff) {
            return callback(new Error(`结束时长不能超过${diff}`));
          } else {
            return callback();
          }
        } else {
          if (
            Number(priceUnitList[indexs].fromTime) <
            priceUnitList[indexs - 1].toTime
          ) {
            return callback(
              new Error(
                `该段开始时长需大于等于${priceUnitList[indexs - 1].toTime}`
              )
            );
          } else if (
            priceUnitList[indexs].toTime > diff ||
            Number(priceUnitList[indexs].toTime) <=
              Number(priceUnitList[indexs].fromTime)
          ) {
            return callback(
              new Error(
                `该段结束时长范围需在${
                  parseInt(priceUnitList[indexs].fromTime) + 1
                }~${diff}之间`
              )
            );
          } else {
            return callback();
          }
        }
      } else {
        return callback();
      }
    },
    //校验添加时段的逻辑
    priceDetail_relus(rule, value, callback) {
      let index = parseInt(rule.field.split("_")[1]);
      let priceDetailList = this.detailForm.priceDetailList;
      let timeStart = priceDetailList[index].timeStart;
      let timeEnd = priceDetailList[index].timeEnd;
      if (!timeStart) {
        return callback(new Error("该时段的开始时间不能为空"));
      }
      if (!timeEnd) {
        return callback(new Error("该时段的结束时间不能为空"));
      }
      if (index !== 0) {
        if (
          priceDetailList[index].timeStart !==
          priceDetailList[index - 1].timeEnd
        ) {
          return callback(
            new Error("该时段的开始时间必须等于上个时段的结束时间")
          );
        }
        let timeCount = 0;
        for (let item of priceDetailList) {
          timeCount += timeDiff(item.timeStart, item.timeEnd, "分钟");
        }
        if (timeCount > 1440) {
          return callback(new Error("时段总和不能大于24小时"));
        }
      }
      return callback();
    },
    //规则核验
    rulesCheck() {
      let flag = false;
      if (this.baseForm.priceType === "组合收费") {
        this.$refs["baseForm"].validate((valid) => {
          if (valid) {
            this.$refs["chargeForm"].validate((valid) => {
              if (valid) {
                this.addPriceUnit_index = -1;
                this.$refs["detailForm"].validate((valid) => {
                  if (valid) {
                    flag = true;
                  } else {
                    flag = false;
                  }
                });
              } else {
                flag = false;
              }
            });
          } else {
            flag = false;
          }
        });
        if (flag) {
          let timeCount = 0;
          let priceDetailList = this.detailForm.priceDetailList;
          for (
            let index = 0, len = priceDetailList.length;
            index < len;
            index++
          ) {
            let item = priceDetailList[index];
            let diff = 0;
            if (item.priceType === "按时长收费") {
              diff = timeDiff(
                item.timeStart,
                item.timeEnd,
                item.priceUnitList[0].countUnit
              );
              if (
                parseInt(
                  item.priceUnitList[item.priceUnitList.length - 1].toTime
                ) < diff
              ) {
                this.$message.error(
                  `时段${index + 1}的时长未满${diff}${
                    item.priceUnitList[0].countUnit
                  }`
                );
                flag = false;
                return flag;
              } else if (
                parseInt(
                  item.priceUnitList[item.priceUnitList.length - 1].toTime
                ) > diff
              ) {
                this.$message.error(
                  `时段${index + 1}的时长大于${diff}${
                    item.priceUnitList[0].countUnit
                  }`
                );
                flag = false;
                return flag;
              }
            } else {
              diff = timeDiff(item.timeStart, item.timeEnd, "分钟");
            }
          }
          for (let item of priceDetailList) {
            timeCount += timeDiff(item.timeStart, item.timeEnd, "分钟");
          }

          if (timeCount < 1440) {
            this.$message.error(`时段总和不满24小时`);
            flag = false;
          } else if (timeCount > 1440) {
            this.$message.error(`时段总和超过24小时`);
            flag = false;
          }
        }
      } else if (this.baseForm.priceType === "按时长收费") {
        this.$refs["baseForm"].validate((valid) => {
          if (valid) {
            this.$refs["chargeForm"].validate((valid) => {
              if (valid) {
                this.$refs.timeRule.$refs["timeRuleForm"].validate((valid) => {
                  if (valid) {
                    flag = true;
                  } else {
                    flag = false;
                  }
                });
              } else {
                flag = false;
              }
            });
          } else {
            flag = false;
          }
        });
        if (flag) {
          let timeCount = 0;
          let priceUnitList = this.$refs.timeRule.timeRuleForm.priceUnitList;

          if (priceUnitList[0].countUnit === "小时") {
            timeCount += priceUnitList[priceUnitList.length - 1].toTime * 60;
          } else {
            timeCount += priceUnitList[priceUnitList.length - 1].toTime;
          }
          let totalMinute = this.baseForm.periodHours * 60;
          if (timeCount < totalMinute) {
            this.$message.error(`时长总和不满${this.baseForm.periodHours}小时`);
            flag = false;
          } else if (timeCount > totalMinute) {
            this.$message.error(`时长总和超过${this.baseForm.periodHours}小时`);
            flag = false;
          }
        }
      }
      return flag;
    },
    //添加时段按钮
    addTimeQuantum() {
      let priceDetailList = this.detailForm.priceDetailList;
      let last_index = priceDetailList.length - 1;
      let first_priceDetail = priceDetailList[0];
      let last_priceDetail = priceDetailList[priceDetailList.length - 1];
      if (first_priceDetail.timeStart === last_priceDetail.timeEnd) {
        this.$message.error(`时段总和已满24小时`);
      } else {
        this.$refs["detailForm"].validate((valid) => {
          if (valid) {
            Object.assign(this.detailRules, {
              [`priceDetailList_${priceDetailList.length}`]: [
                { validator: this.priceDetail_relus, trigger: "blur" },
              ],
              [`priceUnitList_${priceDetailList.length}_0`]: [
                { validator: this.priceUnit_relus, trigger: "blur" },
              ],
            });
            this.detailForm.priceDetailList.push({
              timeStart: priceDetailList[last_index].timeEnd,
              timeEnd: "",
              priceType: "按时长收费",
              highestCharge: "",
              onceCharge: "",
              priceUnitList: [
                {
                  //生成随机数做key值
                  id: Math.random(),
                  //时长的开始时长
                  fromTime: "0",
                  //时长的结束时长
                  toTime: "",
                  //时长的时长单位
                  countUnit: "小时",
                  //收费的金额
                  chargeAmount: "",
                  //收费的时长
                  chargeTime: "",
                  //收费的时长单位
                  chargeUnit: "小时",
                },
              ],
            });
          } else {
            return false;
          }
        });
      }
    },
    //删除时段按钮
    delTimeQuantum() {
      let priceDetailList = this.detailForm.priceDetailList;
      let last_index = priceDetailList.length - 1;
      this.detailForm.priceDetailList.splice(last_index, 1);
    },
    //添加时长
    addPriceUnit(index) {
      this.addPriceUnit_index = index;
      // index为当前哪一个时段
      this.$refs["detailForm"].validate((valid) => {
        if (valid) {
          let priceUnitList =
            this.detailForm.priceDetailList[index].priceUnitList;
          let last_priceUnit = priceUnitList[priceUnitList.length - 1];
          let timeStart = this.detailForm.priceDetailList[index].timeStart;
          let timeEnd = this.detailForm.priceDetailList[index].timeEnd;
          let diff = timeDiff(timeStart, timeEnd, priceUnitList[0].countUnit);
          if (last_priceUnit.toTime >= diff) {
            this.$message.error(
              `时长${priceUnitList.length}的结束时间已经为最大值`
            );
          } else {
            Object.assign(this.detailRules, {
              [`priceUnitList_${index}_${priceUnitList.length}`]: [
                { validator: this.priceUnit_relus, trigger: "blur" },
              ],
            });
            this.detailForm.priceDetailList[index].priceUnitList.push({
              id: Math.random(),
              fromTime: last_priceUnit.toTime,
              toTime: "",
              countUnit: last_priceUnit.countUnit,
              chargeAmount: "",
              chargeTime: "",
              chargeUnit: "小时",
            });
          }
        } else {
          return false;
        }
      });
      return;
    },
    periodHoursChange() {
      this.$refs.timeRule.reset();
    },
    //删除时长
    delPriceUnit(index, indexs) {
      this.detailForm.priceDetailList[index].priceUnitList.splice(indexs, 1);
    },
    async saveBtn() {
      let rulesRes = this.rulesCheck();
      if (rulesRes) {
        this.loading = true;
        let priceDetailList = JSON.parse(
          JSON.stringify(this.detailForm.priceDetailList)
        );
        for (let item of priceDetailList) {
          if (item.priceType !== "按时长收费") {
            item.priceUnitList = [];
          }
          for (let items of item.priceUnitList) {
            delete items.id;
          }
        }

        let priceUnitList = null;
        if (this.baseForm.priceType === "按时长收费") {
          priceUnitList = JSON.parse(
            JSON.stringify(this.$refs.timeRule.timeRuleForm.priceUnitList)
          );
          for (let item of priceUnitList) {
            delete item.id;
          }
        }
        if (this.baseForm.priceType === "组合收费") {
          this.$set(this.baseForm, "periodHours", 24);
        }
        let params = {};
        if (this.baseForm.priceType === "按时长收费") {
          params.priceUnitList = priceUnitList;
        } else {
          params.priceDetailList = priceDetailList;
        }
        try {
          let res = await this.$http.post("/price/update", {
            ...this.baseForm,
            ...this.chargeForm,
            parkingLotId: this.$route.params.id,
            freeIncluded: this.chargeForm.freeIncluded ? 1 : 0,
            ...params,
            priceId: this.priceId,
          });
          if (res.code === 0) {
            this.$message.success("规则编辑成功");
            this.show = false;
            this.$emit("refreshData");
          } else {
            this.$message.error(res.msg);
          }
        } catch (err) {
          this.$message.error(err);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
#chargingRules {
  .el-dialog__wrapper {
    .el-dialog {
      width: 1100px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .basicsInfo {
          padding: 0;
          background-color: white;
          h3 {
            padding-left: 7px;
            margin-bottom: 15px;
            color: black;
            &::before {
              width: 4px;
            }
          }
          .el-form {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .el-form-item {
              width: 55%;
              &:nth-child(2n) {
                width: 34%;
              }
              .el-form-item__label {
                color: rgba(20, 34, 57, 0.62);
                font-size: 12px;
              }
              .el-form-item__content {
                .el-input,
                .el-select,
                .el-textarea {
                  max-width: 265px;
                  width: 100%;
                }
                .el-textarea {
                  height: 60px;
                  .el-textarea__inner {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
        .charge-preview {
          display: flex;
          justify-content: space-between;
          .charge {
            width: 65%;
            .basicsInfo {
              .el-form {
                .freeDuration {
                  display: flex;
                  justify-content: space-between;
                  .el-form-item {
                    width: 100%;
                    .el-form-item__content {
                      width: 265px;
                      display: flex;
                      justify-content: space-between;
                      .el-input {
                        width: 185px;
                      }
                      .el-select {
                        width: 73px;
                        margin-left: 8px;
                        .el-input {
                          width: 73px;
                        }
                      }
                    }
                  }
                  .el-checkbox {
                    margin-left: 14px;
                    .el-checkbox__input {
                      .el-checkbox__inner {
                        border-color: $main-color;
                      }
                    }
                    .el-checkbox__label {
                      font-size: 12px;
                      color: $main-color;
                      line-height: 32px;
                    }
                  }
                }
                .el-form-item {
                  width: 100%;
                }
                .once,
                .freeLeaveMinutes {
                  width: 365px;
                  display: flex;
                  justify-content: space-between;
                  .el-form-item {
                    width: initial;
                    .el-form-item__content {
                      font-size: 12px;
                      .el-input {
                        width: 96px;
                      }
                    }
                  }
                  .onceCharge {
                    .el-form-item__label {
                      width: initial !important;
                    }
                    .el-form-item__content {
                      margin-left: 0px !important;
                      display: flex;
                      justify-content: space-between;
                    }
                  }
                }
                .freeLeaveMinutes {
                  .el-form-item {
                    .el-form-item__content {
                      .el-input {
                        margin-right: 8px;
                      }
                    }
                  }
                }
              }
            }
            .detailRulesSet {
              border: 1px solid rgba(20, 34, 57, 0.25);
              & > nav {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 7px 15px;
                background-color: rgba(32, 44, 64, 0.04);
              }
              .setContent {
                padding: 15px;
                .el-form {
                  .timeQuantum {
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    border-bottom: 1px dashed rgba(112, 112, 112, 0.44);
                    &:last-child {
                      margin-bottom: 0;
                      border-bottom: none;
                    }

                    .el-form-item__label {
                      font-size: 12px;
                    }
                    .priceDetailList {
                      margin-bottom: 0;
                      .el-form-item__content {
                        display: flex;
                        font-size: 12px;
                        .time {
                          display: flex;
                          .el-date-editor.el-input {
                            width: 80px;
                            .el-input__inner {
                              padding-right: 0;
                            }
                          }
                          .until {
                            margin: 0 10px;
                          }
                        }
                        .highestCharge {
                          margin-left: 20px;
                          font-size: 12px;
                          .el-input {
                            width: 96px;
                          }
                        }
                      }
                    }
                    .longCharge {
                      & > .el-form-item {
                        & > .el-form-item__content {
                          display: flex;
                          .priceUnit {
                            display: flex;
                            & > div {
                              display: flex;
                              .el-input {
                                width: 55px;
                                .el-input__inner {
                                  padding: 0 10px;
                                }
                              }
                              .el-select {
                                width: 70px;
                                margin-left: 5px;
                                .el-input {
                                  width: 100%;
                                }
                              }
                            }
                            & > span {
                              margin: 0 6px;
                            }
                          }
                          .priceUnitList_charge {
                            display: flex;
                            margin-left: 15px;
                            & > span {
                              &:first-child {
                                margin-right: 10px;
                              }
                            }
                            & > div {
                              display: flex;
                              .el-input {
                                width: 55px;
                                .el-input__inner {
                                  padding: 0 10px;
                                }
                              }
                              .el-select {
                                width: 70px;
                                margin-left: 5px;
                                .el-input {
                                  width: 100%;
                                }
                              }
                            }
                            .obliqueLine {
                              margin: 0 5px;
                            }
                            .chargeAmount {
                              .el-input {
                                width: 63px;
                              }
                            }
                          }
                          & > .el-button {
                            margin-left: 10px;
                            padding: 0;
                            height: 32px;
                            line-height: 32px;
                          }
                        }
                      }
                      .addPriceUnit {
                        width: calc(100% - 53px);
                        border-color: $main-color;
                        span {
                          color: $main-color;
                          i {
                            font-size: 14px;
                          }
                        }
                      }
                    }
                    .onceCharge {
                      .el-form-item {
                        .el-form-item__content {
                          width: 96px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .preview {
            width: 34%;
            .basicsInfo {
              #rulePreview {
                ul {
                  li {
                    span {
                      color: rgba(20, 34, 57, 1);
                    }
                  }
                }
                .remark {
                  color: rgba(20, 34, 57, 1);
                }
              }
            }
          }
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
