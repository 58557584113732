const timeDiff = function (timeStart, timeEnd, type = "小时") {
  timeStart = Number(timeStart);
  timeEnd = Number(timeEnd);
  let diff = timeEnd - timeStart;
  if (type === "小时") {
    if (diff >= 0) {
      if (diff === 0) {
        return 24;
      } else {
        return Math.ceil(diff / 100);
      }
    } else {
      return Math.ceil((2400 - timeStart + timeEnd) / 100);
    }
  } else if (type === "分钟") {
    if (diff >= 0) {
      if (diff === 0) {
        return 1440;
      } else {
        timeStart = String(timeStart).padStart(4, "0");
        timeEnd = String(timeEnd).padStart(4, "0");
        return (
          timeEnd.slice(0, 2) * 60 +
          Number(timeEnd.slice(2)) -
          (timeStart.slice(0, 2) * 60 + Number(timeStart.slice(2)))
        );
      }
    } else {
      timeStart = String(timeStart).padStart(4, "0");
      timeEnd = String(timeEnd).padStart(4, "0");
      return (
        1440 -
        (timeStart.slice(0, 2) * 60 + Number(timeStart.slice(2))) +
        (timeEnd.slice(0, 2) * 60 + Number(timeEnd.slice(2)))
      );
    }
  }
};

export { timeDiff };
